.modalImageContainer {
  background: #fffaf4;
  padding-inline: 32px;
  margin-block-end: 25px;
  border: 1px dashed #ffe4c5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.imageContainer {
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1/1;
}

.uploadIcon {
  cursor: pointer;
  display: flex;
}

.imageDisplayControls {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.imageContolBtns {
  width: 100%;
  /* min-width: 255px; */
  padding-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

@media (width<540) {
  .imageContolBtns {
    flex-wrap: wrap;
    gap: 5%;
  }
}

.deleteImageBtn {
  min-width: 47.5%;
  background: #ffeae9;
  color: #d80e01;
  border: 1px solid #ffeae9;
  border-radius: 3px;
  transition: all 300ms ease-in;
}

.deleteImageBtn:hover {
  background: #d80e01;
  color: #fff;
  border: 1px solid #d80e01;
}

.chooseNewImageBtn {
  min-width: 47.5%;
  background: #fff;
  color: #fd9f28;
  border: 1px solid #fd9f28;
  border-radius: 3px;
  transition: all 300ms ease-in;
}

.chooseNewImageBtn:hover {
  background: #fd9f28;
  color: #fff;
  border: 1px solid #fd9f28;
}

.modalImageText {
  color: #9ba49a;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

.formField {
  padding: 0.8rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #041d05;
}

.formField::placeholder {
  color: rgba(4, 29, 5, 0.2);
}

.formLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #041d05;
}

.formCheckboxes {
  background: #ffffff;
  padding: 14px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: flex-start;
  gap: 14px;
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.formCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.formCheckboxIput {
  width: 14px;
  height: 14px;
  border: 1px solid #f1e7da;
}

.formCheckboxLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #9ba49a;
}

.btnColor {
  background: #fd9f28;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 0.8rem;
  transition: all 300ms ease-in;
}

.btnColor:hover {
  background: #339e35;
  color: #f5f5f5;
  border: 1px solid #339e35;
}
