.addPlanTypeToStoreModalForm {
  width: 100%;
  font-family: 'BR Firma';
}

.addPlanTypeToStoreModalFormTitle {
  color: #041d05;
  font-size: 14px;
  line-height: 140%;
}

.addPlanTypeToStoreModalFormSelect {
  font-family: 'BR Firma', sans-serif;
  font-style: inherit;
  font-size: 14px;
  background: rgba(255, 255, 255, 0);
  color: #041d05;
  padding-block: 16px;
  padding-inline: 16px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #f1e7da;
}

.addPlanTypeToStoreModalFormOptGroup {
  font-family: 'BR Firma', sans-serif;
  font-style: inherit;
  color: #041d05;
  font-size: 14px;
  font-weight: 300;
  text-shadow: none;
}
