.contactFormInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contactFormInfoLabelContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.contactFormGridLayout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 48px;
  row-gap: 24px;
}

.contactFormInfoLabel,
.switchInputLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #041d0580;
  padding: 0%;
  margin: 0%;
}

.contactFormInfoValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #041d05b2;
  padding: 0%;
  margin: 0%;
}

.switchContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  align-items: center;
}

.switchInputCheckbox {
  display: none;
}

.switchInput {
  width: fit-content;
  outline: none;
  border: none;
  background: transparent;
  margin: 0;
  padding-inline-start: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 36px;
  height: 20px;
  border-radius: 12px;
  background: #fdf7f0;
}

.switchInputDot {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #dbcab4;
  transition: 0.5s;
}

.switchInputCheckbox:checked + .switchInput {
  background: #fd9f28;
}

.switchInputCheckbox:checked + .switchInput > .switchInputDot {
  left: 18px;
  background: #f5d6ae;
}

.switchInputLabel {
  color: #fd9f28;
}
