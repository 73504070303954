.blogContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px 30px;
}

.blogPostBackground {
  /* min-height: 88dvh; */
  background-color: #f9faff;
  font-family: "BR Firma";
}

.imageParentContainer {
  position: relative;
}

.imageContainer {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

@media (min-width: 767px) {
  .imageContainer {
    width: 178px;
  }
  .blogContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blogPostStatus {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  padding-block: 3px;
  padding-inline: 9px;
  color: #fff;
  border-radius: 3px;
}

.blogPostActiveStatus {
  background-color: #339e35;
}

.blogPostInactiveStatus {
  background-color: #d80e01;
}

.blogPostTitle {
  color: #041d05;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.blogPostDetails {
  font-family: BR Firma;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-block: 0;
}

/* Chrome, Safari, Edge, Opera */
.numberInputClassName::-webkit-outer-spin-button,
.numberInputClassName::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInputClassName[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.imageUploadSection {
  background-color: #ffe4c533;
  padding-block: 2rem;
  margin-block: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imageContainer {
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;

    /* .uploadIcon {
        width: inherit;
        display: flex;
        object-fit: cover;
        object-position: center;
      } */
  }

  .caption {
    color: #fd9f28;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .captionText {
    color: #9ba49a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}
