.modalOverlay {
  width: calc(100vw - 260px);
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-block: 3rem;
  z-index: 10000000000;
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .modalOverlay {
    width: 100vw;
    left: 0px;
  }
}

.modal {
  position: relative;
  background-color: white;
  width: 90%;
  max-width: 450px;
  max-height: 100%;
  overflow-y: scroll;
  padding-block: 30px 69px;
  padding-inline: 32px;
  background: #ffffff;
  border-radius: 20px;
}

.modal::-webkit-scrollbar {
  display: block;
  background-color: transparent;
  width: 5px;
  height: 15px;
}

.modal::-webkit-scrollbar-track {
  display: block;
  width: 5px;
  height: 15px;
  background-color: rgba(229, 247, 255, 0.4);
}

.modal::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 50px;
  height: 15px;
  background-color: #716a6a;
}

.modalHeader {
  font-family: "BR Firma";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.modalHeaderTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalCloseBtn {
  width: 26px;
  height: 26px;
  background: #ffffff;
  color: #374935;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: self-start;
  outline: transparent;
  border: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);
  transition: all 300ms ease-in;
}

.modalCloseBtn:hover {
  background: #f5f5f5;
  color: crimson;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}

.modalTitle {
  max-width: 30ch;
}

.modalHeaderTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-transform: capitalize;
  color: #374935;
}

.subTitle {
  color: #374935;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  text-align: start;
  opacity: 0.5;
}

.modalBody {
  font-family: "BR Firma";
  margin-bottom: 10px;
}

.transparentBtn {
  outline-color: transparent;
  border-color: transparent;
  background: transparent;
}
