.modalImageContainer {
  width: 100%;
  background: #fffaf4;
  padding-inline: 32px;
  margin-block-end: 25px;
  border: 1px dashed #ffe4c5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.uploadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 10px;
  background: transparent;
  outline: transparent;
  border: transparent;
}

.uploadIcon {
  width: unset;
  height: unset;
  cursor: pointer;
  display: flex;
  padding-block-start: 10px;
}

.imageContainer {
  cursor: pointer;
  display: flex;
  max-width: 300px;
  aspect-ratio: 1/1;
}

.imageContolBtns {
  width: 100%;
  min-width: 255px;
  padding-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

@media (width<540) {
  .imageContolBtns {
    flex-wrap: wrap;
    gap: 5%;
  }
}

.deleteImageBtn {
  min-width: 47.5%;
  background: #ffeae9;
  color: #d80e01;
  border: 1px solid #ffeae9;
  border-radius: 3px;
  transition: all 300ms ease-in;
}

.deleteImageBtn:hover {
  background: #d80e01;
  color: #fff;
  border: 1px solid #d80e01;
}

.chooseNewImageBtn {
  min-width: 47.5%;
  background: #fff;
  color: #fd9f28;
  border: 1px solid #fd9f28;
  border-radius: 3px;
  transition: all 300ms ease-in;
}

.chooseNewImageBtn:hover {
  background: #fd9f28;
  color: #fff;
  border: 1px solid #fd9f28;
}

.modalImageText {
  color: #9ba49a;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

/* .modalImageText:last-of-type {
  padding-block-end: 16px;
} */

.modalImageSpan {
  cursor: pointer;
  color: #fd9f28;
  font-weight: 600;
  padding-inline-end: 0.3rem;
  transition: all 300ms ease-in;
}

.modalImageSpan:hover {
  color: #339e35;
}

