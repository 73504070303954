body {
	font-family: 'BR Firma', sans-serif;
}

.nav-item .indicator {
	background: #ff9900 !important;
	border-radius: 50%;
}

.create-sub {
	margin-top: -39px;
}

#jvm-markers-group {
	display: none;
}

i.bi.bi-eye-fill {
	color: #019d1d;
}

i.bi.bi-pencil-square {
	color: #ff9900;
}

i.bi.bi-trash-fill {
	color: red;
}

.flex {
	display: flex;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.ml-2 {
	margin-left: 0.5rem;
}

.ml-4 {
	margin-left: 2rem;
}
.my-8 {
	margin: 2rem 0;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}
.meal-duration-container {
	border: 1px solid #ddd;
	padding: 20px;
	margin: 10px 0;
}
.meal-duration-btn-minus {
	border: none;
	outline: none;
	background-color: #fcb92c;
	color: white;
	font-size: 18px;
	padding: 0 10px;
	border-radius: 5px;
}
.meal-duration-btn-plus {
	border: none;
	outline: none;
	background-color: #fcb92c;
	color: white;
	font-size: 18px;
	padding: 0 8px;
	border-radius: 5px;
}
.meal-duration-text {
	font-size: 16px;
	margin: 0 20px;
}

.sidebar-link.dropdown-toggle:after {
	/* margin-left: 6rem !important; */
	transform: rotate(-45deg) !important;
	vertical-align: 0 !important;
	float: right;
	margin-top: 10px;
}
