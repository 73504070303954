.createBlogCategory {
  min-height: 87dvh;
  background-color: #f9faff;
  font-family: BR Firma;

  /* Chrome, Safari, Edge, Opera */
  .numberInputClassName::-webkit-outer-spin-button,
  .numberInputClassName::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .numberInputClassName[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .formGroup {
    width: 100%;
    margin-block-end: 1.5rem;

    @media (width >= 524px) {
      max-width: 50%;
    }
  }
}
