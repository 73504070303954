.modalBody {
  margin-bottom: 10px;
}

.modalImageContainer {
  background: #fffaf4;
  padding-inline: 32px;
  margin-block-end: 25px;
  border: 1px dashed #ffe4c5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.uploadIcon {
  cursor: pointer;
  display: flex;
}

.modalImageText {
  color: #9ba49a;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  margin: 0;
}

.modalImageSpan {
  cursor: pointer;
  display: flex;
}

.formLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #041d05;
}

.formField {
  padding: 0.8rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #041d05;
}

.formField::placeholder {
  color: rgba(4, 29, 5, 0.2);
}

.btnColor {
  background: #fd9f28;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 0.8rem;
  transition: all 300ms ease-in;
}

.btnColor:hover {
  background: #339e35;
  color: #f5f5f5;
  border: 1px solid #339e35;
}
