/* FONT FAMILIES */
@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Thin'), local('./assets/fonts/BRFirma-Thin'),
    url('./assets/fonts/BRFirma-Thin/BRFirma-Thin.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Thin/BRFirma-Thin.woff') format('woff'),
    url('./assets/fonts/BRFirma-Thin/BRFirma-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Extra Light'), local('./assets/fonts/BRFirma-ExtraLight'),
    url('./assets/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.woff2')
      format('woff2'),
    url('./assets/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.woff')
      format('woff'),
    url('./assets/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Light'), local('./assets/fonts/BRFirma-Light'),
    url('./assets/fonts//BRFirma-Light/BRFirma-Light.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Light/BRFirma-Light.woff') format('woff'),
    url('./assets/fonts/BRFirma-Light/BRFirma-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Regular'), local('./assets/fonts/BR-Firma-Regular'),
    url('./assets/fonts/BRFirma-Regular/BRFirma-Regular.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Regular/BRFirma-Regular.woff') format('woff'),
    url('./assets/fonts/BRFirma-Regular/BRFirma-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Medium'), local('./assets/fonts/BRFirma-Medium'),
    url('./assets/fonts/BRFirma-Medium/BRFirma-Medium.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Medium/BRFirma-Medium.woff') format('woff'),
    url('./assets/fonts/BRFirma-Medium/BRFirma-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma SemiBold'), local('./assets/fonts/BRFirma-SemiBold'),
    url('./assets/fonts/BRFirma-SemiBold/BRFirma-SemiBold.woff2')
      format('woff2'),
    url('./assets/fonts/BRFirma-SemiBold/BRFirma-SemiBold.woff') format('woff'),
    url('./assets/fonts/BRFirma-SemiBold/BRFirma-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Bold'), local('./assets/fonts/BRFirma-Bold'),
    url('./assets/fonts/BRFirma-Bold/BRFirma-Bold.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Bold/BRFirma-Bold.woff') format('woff'),
    url('./assets/fonts/BRFirma-Bold/BRFirma-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BR Firma';
  src: local('BR Firma Black'), local('./assets/fonts/BRFirma-Black'),
    url('./assets/fonts/BRFirma-Black/BRFirma-Black.woff2') format('woff2'),
    url('./assets/fonts/BRFirma-Black/BRFirma-Black.woff') format('woff'),
    url('./assets/fonts/BRFirma-Black/BRFirma-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'BR Firma';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'fira code', 'Courier New', monospace;
}

.ql-editor {
  min-height: 200px;
  max-height: 300px;
  overflow-y: scroll;
}
