@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
/* @import url('http://fonts.cdnfonts.com/css/br-firma'); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.reviewBtnWarning,
.reviewBtnDanger {
  width: 45%;
  border-radius: 3px;
}

.reviewBtnWarning {
  border: 1px solid #fd9f28;
  background-color: #fff;
  color: #fd9f28;
  transition: all 300ms ease-in;
}

.reviewBtnWarning:hover {
  background-color: #fd9f28;
  color: #fff;
  border: 1px solid #fd9f28;
}

.reviewBtnDanger {
  border: 1px solid #ffeae9;
  background-color: #ffeae9;
  color: #d80e01;
  transition: all 300ms ease-in;
}

.reviewBtnDanger:hover {
  background-color: #d80e01;
  color: #fff;
  border: 1px solid #d80e01;
}

.toggle-bg {
  background: #339e351a;
  padding: 10px;
  border-radius: 7px;
}

.toggle-bg button.active {
  background-color: #ffffff; /* Set the background color for active buttons */
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #339e35;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.toggle-bg button.inactive {
  background-color: transparent;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #96aa8e;
  border: 1px solid transparent;
}

.tab-content h3 {
  color: #041d05;
  font-size: 18px;
  line-height: 19px;
  font-weight: 700;
}

.tab-content h4 {
  color: #041d05;
  font-size: 18px;
  line-height: 19px;
  font-weight: 400;
}

.image-container {
  position: relative;
}

.centered-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* semi-transparent black */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.centered-button button {
  border: 1px solid #ffffff99;
  border-radius: 3px;
  padding: 6px 10px;
  background-color: transparent;
}

.sf-bg {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sf-bg h4 {
  color: #041d05cc;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
}

.sf-bg h5 {
  color: #041d0580;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.sf-bg h6 {
  color: #374935;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.store-locator {
  align-items: center;
}

.store-locator h4 {
  color: #041d05;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}

.store-locator h3 {
  color: #041d05;
  font-size: 14px;
  line-height: 19px;
  width: 76%;
  font-weight: 500;
}

.store-locator p {
  color: #041d05;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}

.error-validation-text {
  color: #bb2124;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 8px;
}
